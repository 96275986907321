const routes = [
  {
    path: '/settings/tickettemplates',
    component: () => {
      return import(
        './vue-components/settings/tickettemplate/TicketTemplateListComponent.vue'
      )
    },
  },
  {
    path: '/settings/tickettemplates/create',
    component: () => {
      return import(
        './vue-components/settings/tickettemplate/TicketTemplateCreateComponent.vue'
      )
    },
  },
  {
    path: '/settings/tickettemplates/edit/:id',
    name: 'editTicketTemplate',
    component: () => {
      return import(
        './vue-components/settings/tickettemplate/TicketTemplateEditComponent.vue'
      )
    },
  },
  {
    path: '/settings/tickettemplates/edit/:id/subticket',
    name: 'addsubticket',
    component: () => {
      return import(
        './vue-components/settings/tickettemplate/TicketTemplateAddSubticketComponent.vue'
      )
    },
  },
  {
    path: '/settings/tickettemplates/edit/:id/subticket/:subticketid',
    name: 'editsubticket',
    component: () => {
      return import(
        './vue-components/settings/tickettemplate/TicketTemplateEditSubticketComponent.vue'
      )
    },
  },
]

export { routes }
