import mitt from 'mitt'
import { defineAsyncComponent, createApp } from 'vue'
import * as Sentry from '@sentry/vue'
import { createRouter, createWebHistory } from 'vue-router'

import 'highlight.js/styles/vs2015.css'
// @ts-ignore
import hljs from 'highlight.js/lib/core'
// @ts-ignore
import bash from 'highlight.js/lib/languages/bash'
// @ts-ignore
import json from 'highlight.js/lib/languages/json'
// @ts-ignore
import hljsVuePlugin from '@highlightjs/vue-plugin'
import './bootstrap'
import { routes } from './routes'
hljs.registerLanguage('bash', bash)
hljs.registerLanguage('json', json)

// @ts-ignore
window.events = mitt()
// @ts-ignore
window.flash = function (message, level = 'success') {
  // @ts-ignore
  window.events.emit('flash', { message, level })
}

const router = createRouter({
  history: createWebHistory(),
  routes,
})

const app = createApp({
  data() {
    return {
      profileMenuIsOpen: false,
      kbMenuIsOpen: false,
      navbarMenuIsOpen: false,
      createMenuIsOpen: false,
    }
  },
})

app.use(hljsVuePlugin)

// @ts-ignore
app.config.isCustomElement = (tag) => tag === 'trix-editor'

app.component(
  'VueTrix',
  defineAsyncComponent(() => import('./vue-components/HitTrix.vue')),
)
app.component(
  'UserportalTemplate',
  defineAsyncComponent(
    () => import('./vue-components/userportal/UserportalTemplate.vue'),
  ),
)
app.component(
  'LoadingComponent',
  defineAsyncComponent(
    () => import('./vue-components/settings/LoadingComponent.vue'),
  ),
)
app.component(
  'NewTicketComponent',
  defineAsyncComponent(() => import('./vue-components/NewTicketComponent.vue')),
)
app.component(
  'TicketComponent',
  defineAsyncComponent(
    () => import('./vue-components/ticket/TicketComponent.vue'),
  ),
)
app.component(
  'UsersComponent',
  defineAsyncComponent(
    () => import('./vue-components/settings/UsersComponent.vue'),
  ),
)
app.component(
  'SettingsEmailTemplates',
  defineAsyncComponent(
    () => import('./vue-components/settings/SettingsEmailTemplates.vue'),
  ),
)
app.component(
  'TicketActionsDropdown',
  defineAsyncComponent(
    () => import('./vue-components/ticket/TicketActionsDropdown.vue'),
  ),
)
app.component(
  'SettingsEmailTemplateShow',
  defineAsyncComponent(
    () => import('./vue-components/settings/SettingsEmailTemplateShow.vue'),
  ),
)
app.component(
  'EmailConfigComponent',
  defineAsyncComponent(
    () => import('./vue-components/settings/EmailConfigComponent.vue'),
  ),
)
app.component(
  'TicketCreationComponent',
  defineAsyncComponent(
    () => import('./vue-components/settings/TicketCreationComponent.vue'),
  ),
)
app.component(
  'CleanupComponent',
  defineAsyncComponent(() => import('./vue-components/CleanupComponent.vue')),
)
app.component(
  'LineChartComponent',
  defineAsyncComponent(
    () => import('./vue-components/statistics/LineChartComponent.vue'),
  ),
)
app.component(
  'BarChartComponent',
  defineAsyncComponent(
    () => import('./vue-components/statistics/BarChartComponent.vue'),
  ),
)
app.component(
  'PieChartComponent',
  defineAsyncComponent(
    () => import('./vue-components/statistics/PieChartComponent.vue'),
  ),
)
app.component(
  'ModifyStatus',
  defineAsyncComponent(
    () => import('./vue-components/settings/ModifyStatus.vue'),
  ),
)
app.component(
  'FlashMessage',
  defineAsyncComponent(
    () => import('./vue-components/components/HitFlash.vue'),
  ),
)
app.component(
  'NewEmailComponent',
  defineAsyncComponent(
    () => import('./vue-components/profile/NewEmailComponent.vue'),
  ),
)
app.component(
  'EditCategoryNameComponent',
  defineAsyncComponent(
    () => import('./vue-components/settings/EditCategoryNameComponent.vue'),
  ),
)
app.component(
  'HitTabbable',
  defineAsyncComponent(
    () => import('./vue-components/components/HitTabbable.vue'),
  ),
)
app.component(
  'TabbableData',
  defineAsyncComponent(
    () => import('./vue-components/components/TabbableData.vue'),
  ),
)
app.component(
  'StatusComponent',
  defineAsyncComponent(
    () => import('./vue-components/ticket/StatusComponent.vue'),
  ),
)
app.component(
  'UsernameEditable',
  defineAsyncComponent(
    () => import('./vue-components/profile/UsernameEditable.vue'),
  ),
)
app.component(
  'HitSelect',
  defineAsyncComponent(
    () => import('./vue-components/components/HitSelectBlade.vue'),
  ),
)
app.component(
  'QueueUserSettings',
  defineAsyncComponent(
    () => import('./vue-components/profile/QueueUserSettings.vue'),
  ),
)
app.component(
  'ScheduledTicketInstances',
  defineAsyncComponent(
    () => import('./vue-components/ticket/ScheduledTicketInstances.vue'),
  ),
)
app.component(
  'NewTicketFromTemplateComponent',
  defineAsyncComponent(
    () =>
      import('./vue-components/components/NewTicketFromTemplateComponent.vue'),
  ),
)
app.component(
  'RelatedTicket',
  defineAsyncComponent(
    () => import('./vue-components/components/RelatedTicket.vue'),
  ),
)
app.component(
  'HistoryEvents',
  defineAsyncComponent(
    () => import('./vue-components/components/HistoryEvents.vue'),
  ),
)
app.component(
  'edit-scheduled-ticket',
  defineAsyncComponent(
    () => import('./vue-components/EditScheduledticketComponent.vue'),
  ),
)
app.component(
  'Datepicker',
  defineAsyncComponent(
    () => import('./vue-components/components/HitDatepicker.vue'),
  ),
)
app.component(
  'Duedate',
  defineAsyncComponent(() => import('./vue-components/ticket/HitDuedate.vue')),
)
app.component(
  'HitHideuntil',
  defineAsyncComponent(
    () => import('./vue-components/ticket/HitHideuntil.vue'),
  ),
)
app.component(
  'HitSelectCategory',
  defineAsyncComponent(
    () => import('./vue-components/ticket/HitSelectCategory.vue'),
  ),
)
app.component(
  'HitSelectCustomer',
  defineAsyncComponent(
    () => import('./vue-components/ticket/HitSelectCustomer.vue'),
  ),
)
app.component(
  'HitSelectOwner',
  defineAsyncComponent(
    () => import('./vue-components/ticket/HitSelectOwner.vue'),
  ),
)
app.component(
  'HitSelectPrio',
  defineAsyncComponent(
    () => import('./vue-components/ticket/HitSelectPrio.vue'),
  ),
)
app.component(
  'HitMoveTicket',
  defineAsyncComponent(
    () => import('./vue-components/ticket/HitMoveTicket.vue'),
  ),
)
app.component(
  'TimereportSelector',
  defineAsyncComponent(() => import('./vue-components/TimereportSelector.vue')),
)
app.component(
  'StatisticsSelector',
  defineAsyncComponent(() => import('./vue-components/StatisticsSelector.vue')),
)
app.component(
  'HitNavbar',
  defineAsyncComponent(() => import('./vue-components/HitNavbar.vue')),
)
app.component(
  'HitButton',
  defineAsyncComponent(
    () => import('./vue-components/components/HitButton.vue'),
  ),
)
app.component(
  'Webhooks',
  defineAsyncComponent(() => import('./vue-components/settings/Webhooks.vue')),
)
app.component(
  'HitPill',
  defineAsyncComponent(() => import('./vue-components/components/HitPill.vue')),
)
app.component(
  'EditCustomer',
  defineAsyncComponent(
    () => import('./vue-components/components/EditCustomer.vue'),
  ),
)
app.component(
  'WebhookShow',
  defineAsyncComponent(
    () => import('./vue-components/settings/WebhookShow.vue'),
  ),
)
app.component(
  'ScheduledTickets',
  defineAsyncComponent(() => import('./vue-components/ScheduledTickets.vue')),
)
app.component(
  'FileInput',
  defineAsyncComponent(
    () => import('./vue-components/components/FileInput.vue'),
  ),
)
app.component(
  'CreateRuleComponent',
  defineAsyncComponent(
    () => import('./vue-components/settings/CreateRuleComponent.vue'),
  ),
)
app.component(
  'EditRuleComponent',
  defineAsyncComponent(
    () => import('./vue-components/settings/EditRuleComponent.vue'),
  ),
)
app.component(
  'PopconfirmDeleteButton',
  defineAsyncComponent(
    () => import('./vue-components/components/PopconfirmDeleteButton.vue'),
  ),
)
app.component(
  'DeleteRuleButton',
  defineAsyncComponent(
    () => import('./vue-components/components/DeleteRuleButton.vue'),
  ),
)
app.component(
  'HelpRuleComponent',
  defineAsyncComponent(
    () => import('./vue-components/settings/HelpRuleComponent.vue'),
  ),
)
app.component(
  'HitTinyEditor',
  defineAsyncComponent(() => import('./vue-components/HitTinyEditor.vue')),
)
app.component(
  'MessageTemplates',
  defineAsyncComponent(
    () => import('./vue-components/profile/MessageTemplates.vue'),
  ),
)
app.component(
  'ProfileApi',
  defineAsyncComponent(() => import('./vue-components/profile/ProfileApi.vue')),
)

app.component(
  'ExportStatistics',
  defineAsyncComponent(
    () => import('./vue-components/components/ExportStatistics.vue'),
  ),
)
app.component(
  'SettingsMenu',
  defineAsyncComponent(
    () => import('./vue-components/settings/SettingsMenu.vue'),
  ),
)

app.component(
  'AdvancedSettings',
  defineAsyncComponent(
    () => import('./vue-components/settings/AdvancedSettings.vue'),
  ),
)

app.component(
  'SettingsMenuEmailComponent',
  defineAsyncComponent(
    () => import('./vue-components/settings/MenuEmailComponent.vue'),
  ),
)
app.component(
  'TicketHeader',
  defineAsyncComponent(
    () => import('./vue-components/ticket/TicketHeader.vue'),
  ),
)
app.component(
  'ProfileSettingsMenu',
  defineAsyncComponent(
    () => import('./vue-components/profile/SettingsMenu.vue'),
  ),
)
app.component(
  'SearchComponent',
  defineAsyncComponent(
    () => import('./vue-components/components/SearchComponent.vue'),
  ),
)
app.component(
  'SavedFiltersComponent',
  defineAsyncComponent(
    () => import('./vue-components/components/SavedFiltersComponent.vue'),
  ),
)
app.component(
  'KnowledgeBaseComponent',
  defineAsyncComponent(
    () => import('./vue-components/components/KnowledgeBaseComponent.vue'),
  ),
)
app.component(
  'KnowledgeBaseCreate',
  defineAsyncComponent(
    () => import('./vue-components/components/KnowledgeBaseCreate.vue'),
  ),
)
app.component(
  'KnowledgeBaseCategory',
  defineAsyncComponent(
    () => import('./vue-components/components/KnowledgeBaseCategory.vue'),
  ),
)
app.component(
  'EditKBArticleComponent',
  defineAsyncComponent(
    () => import('./vue-components/components/EditKBArticleComponent.vue'),
  ),
)
app.component(
  'KBArticleComponent',
  defineAsyncComponent(
    () => import('./vue-components/components/KBArticleComponent.vue'),
  ),
)
app.component(
  'ApiDocumentation',
  defineAsyncComponent(
    () => import('./vue-components/about/ApiDocumentation.vue'),
  ),
)
app.component(
  'AttachmentImageViewer',
  defineAsyncComponent(
    () => import('./vue-components/ticket/AttachmentImageViewer.vue'),
  ),
)
app.component(
  'DownloadTicketAttachments',
  defineAsyncComponent(
    () => import('./vue-components/components/DownloadTicketAttachments.vue'),
  ),
)
app.component(
  'ScheduledTicketEnding',
  defineAsyncComponent(
    () => import('./vue-components/scheduledtickets/ScheduledTicketEnding.vue'),
  ),
)

app.component(
  'ChangeQueueName',
  defineAsyncComponent(
    () => import('./vue-components/settings/ChangeQueueName.vue'),
  ),
)

app.component(
  'ThemeEditor',
  defineAsyncComponent(
    () => import('./vue-components/settings/ThemeEditor.vue'),
  ),
)

app.component(
  'CreateRole',
  defineAsyncComponent(
    () => import('./vue-components/settings/CreateRole.vue'),
  ),
)

app.component(
  'ModifyRole',
  defineAsyncComponent(
    () => import('./vue-components/settings/ModifyRole.vue'),
  ),
)

app.component(
  'StatusIndex',
  defineAsyncComponent(
    () => import('./vue-components/settings/status/StatusIndex.vue'),
  ),
)

app.component(
  'ProfileIndex',
  defineAsyncComponent(
    () => import('./vue-components/profile/ProfileIndex.vue'),
  ),
)

app.component(
  'TwoFaIndex',
  defineAsyncComponent(
    () => import('./vue-components/profile/2fa/TwoFaIndex.vue'),
  ),
)

app.component(
  'EditTicketComponent',
  defineAsyncComponent(
    () => import('./vue-components/ticket/EditTicketComponent.vue'),
  ),
)

app.component(
  'SplitTicketComponent',
  defineAsyncComponent(
    () => import('./vue-components/ticket/SplitTicketComponent.vue'),
  ),
)

app.component(
  'TicketMessageComponent',
  defineAsyncComponent(
    () => import('./vue-components/ticket/TicketMessageComponent.vue'),
  ),
)

app.component(
  'ToggleTicketMessageComponent',
  defineAsyncComponent(
    () => import('./vue-components/ticket/ToggleTicketMessageComponent.vue'),
  ),
)

app.component(
  'EditPrivateMessage',
  defineAsyncComponent(
    () => import('./vue-components/ticket/EditPrivateMessage.vue'),
  ),
)

app.component(
  'UnlinkTicket',
  defineAsyncComponent(
    () => import('./vue-components/ticket/UnlinkTicket.vue'),
  ),
)

app.component(
  'ChooseTemplateComponent',
  defineAsyncComponent(
    () => import('./vue-components/components/ChooseTemplateComponent.vue'),
  ),
)
app.component(
  'AutocompleteSubmitter',
  defineAsyncComponent(
    () => import('./vue-components/AutocompleteSubmitter.vue'),
  ),
)
app.component(
  'HitInput',
  defineAsyncComponent(
    () => import('./vue-components/components/HitInput.vue'),
  ),
)
app.component(
  'HitToggle',
  defineAsyncComponent(
    () => import('./vue-components/components/HitToggle.vue'),
  ),
)
app.component(
  'RecipientComponent',
  defineAsyncComponent(
    () => import('./vue-components/ticket/RecipientComponent.vue'),
  ),
)

const configs = [
  {
    domain: 'support.devpeak.se',
    sampleRate: 1.0,
  },
  {
    domain: 'arende.ssis.nu',
    sampleRate: 1.0,
  },
  {
    domain: 'egensupport.se',
    sampleRate: 1.0,
  },
  {
    domain: 'ostrareal.devpeak.se',
    sampleRate: 1.0,
  },
  {
    domain: 'demo.dpit.se',
    sampleRate: 1.0,
  },
  {
    domain: 'localhost',
    sampleRate: 1.0,
  },
  {
    domain: /^\//, // Needed for vue to automatically inject sentry-trace header on outgoing requests
    sampleRate: 1.0,
  },
]

// @ts-ignore
app.config.errorHandler = (msg, vm, info) => {
  console.error(msg, vm, info)
}

Sentry.init({
  app,
  dsn: 'https://f73d633affa5487886eb06a721f09a10@sentry.rm-rf.se/3',
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: ['localhost', /^\//],
    }),
  ],
  logErrors: false,
  trackComponents: true,
  // @ts-ignore
  environment: window.sentrydata.environment,
  // @ts-ignore
  release: window.sentrydata.release,
  sampleRate: 1.0,
  tracesSampleRate:
    configs.filter((row) => {
      return row.domain === window.location.hostname
    })?.[0]?.sampleRate ?? '1.0',
  tracePropagationTargets: configs.map((row) => {
    return row.domain
  }),
})

// @ts-ignore
if (window.sentrydata.user) {
  // @ts-ignore
  Sentry.setUser({ email: window.sentrydata.user })
}

// @ts-ignore
window.sentry = Sentry

app.use(router)
app.mount('#app')

document.addEventListener('trix-file-accept', function (event) {
  event.preventDefault()
})
